import React, {useState, useEffect, useRef} from "react";
import {useLocation} from "react-router-dom";
import Status from "./Status";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function HomePage() {
  const [showStatus, setShowStatus] = useState(false);
  const [queryParams, setQueryParams] = useState({id: null, svc: null});
  const queryParsedRef = useRef(false); // Ref to track if query has been parsed
  const query = useQuery();

  useEffect(() => {
    if (!queryParsedRef.current) {
      let id = query.get("id");
      if (id) id = id.toUpperCase();
      const svc = query.get("svc");
      setQueryParams({id, svc});
      queryParsedRef.current = true; // Mark query as parsed
      console.log(`queryParams: ${JSON.stringify({id, svc})}`);
    }
  }, [query]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowStatus(true);
    }, 600); // 1000 milliseconds = 1 seconds
    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="flex flex-col items-center pt-20">
      {showStatus && (
        <main role="main" className="animate-fade">
          <Status id={queryParams.id} svc={queryParams.svc} />
          <p className="hidden">ID: {queryParams.id}</p>
          <p className="hidden">SVC: {queryParams.svc}</p>
        </main>
      )}
    </div>
  );
}

export default HomePage;
