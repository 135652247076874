// statusContentHelper.js
export const getStatusContent = (status) => {
  let text, image, note;
  // Ensure status is defined and uppercase
  status = status ? status : "";
  switch (status) {
    case "":
      text = "???";
      image = "loading-200x200.gif";
      note = "Checking status...";
      break;
    case "TERMINATED":
    case "ERROR":
      text = "Recycled";
      image = "recycle-t-200x200.png";
      note = "When you stop using your instance we recycle it for you.";
      break;
    case "PENDING":
    case "LAUNCHING":
    case "CREATING":
      text = "Configuring...";
      image = "loading-200x200.gif";
      note =
        "Depending on demand, configuring your instance may take 10-15 minutes.";
      break;
    case "RESUMING":
      text = "Resuming...";
      image = "loading-200x200.gif";
      note =
        "Depending on demand, resuming your instance may take 3-5 minutes.";
      break;
    case "RUNNING":
      text = "Ready!";
      image = "start-t-200x200.png";
      note = "Your instance is ready, enjoy the challenge.";
      break;
    case "STOPPING":
      text = "Suspending...";
      image = "loading-200x200.gif";
      note = "When there is no activity we suspend your instance for you.";
      break;
    case "STOPPED":
      text = "Suspended";
      image = "start-t-200x200.png";
      note = "When you stop using your instance it is automatically suspended.";
      break;
    case "MAINTENANCE":
      text = "We're sorry";
      image = "error-t-200x200.png";
      note =
        "The service is temporary unavailable due to maintenance. Please try again later.";
      break;
    default:
      text = status;
      image = "error-t-200x200.png";
      note =
        "This is an unhandled status, please share a screenshot with marco.looy@pega.com";
      break;
  }
  return {text, image, note};
};
