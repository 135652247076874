import React, {useEffect} from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Logo from "./components/Logo";
import Footer from "./components/Footer";
import HomePage from "./components/HomePage";
import "./App.css";

function App() {
  useEffect(() => {
    // Unregister old service workers
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
          console.log("Unregistered service worker:", registration);
        });
      });

      // Clear all caches
      if ("caches" in window) {
        caches.keys().then((cacheNames) => {
          cacheNames.forEach((cacheName) => {
            caches.delete(cacheName);
            console.log("Cleared cache:", cacheName);
          });
        });
      }

      // Optionally notify users to refresh
      // navigator.serviceWorker.getRegistrations().then((registrations) => {
      //   if (registrations.length > 0) {
      //     alert(
      //       "A new version of the app is available. Please refresh your browser."
      //     );
      //   }
      // });
    }
  }, []);

  return (
    <Router>
      <div className="flex flex-col min-h-screen bg-black text-white">
        <header className="flex justify-center py-4 z-0">
          <Logo />
        </header>
        <main className="flex-grow z-10">
          <Routes>
            <Route path="/" element={<HomePage />} />
          </Routes>
        </main>
        <footer className="flex justify-center py-4 z-0">
          <Footer />
        </footer>
      </div>
    </Router>
  );
}

export default App;
