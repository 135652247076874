import React from "react";

const Logo = () => {
  return (
    <div className="flex items-center absolute left-1/2 transform -translate-x-1/2 cursor-pointer">
      <div className="flex-shrink-0 mt-8 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 180 79.2"
          role="img"
          focusable="false"
          alt="Pega horse"
          preserveAspectRatio="xMaxYMax meet"
          className="w-36 h-15"
        >
          <defs>
            <style>
              {`  
                .a { fill: #fff; }  
                .b { fill: #00a6a7; }  
              `}
            </style>
          </defs>
          <g transform="translate(-98 -267)">
            <path
              className="a"
              d="M92.547,11.565a53.233,53.233,0,0,0-.825,21.6q-2.836-.561-5.8-1.1C44.526,25.33,32.047,45.735,36.874,62.27A278.151,278.151,0,0,0,0,79.157c16.249-39,51.551-73.286,81.356-77.793C91.074.088,93.812,6.4,92.547,11.565ZM179.653,42.81s-1.814,3.232-2.628,4.4-3.353,1.957-5.552.9c-1-.473-10.411-5.959-14.006-5.651-18.228,1.594-23.186-5.915-23.769-6.6s-1.682-.363-.9.978c3.991,6.849,15.82,10.027,15.82,10.027a44.669,44.669,0,0,1,3.463,12.676,53.145,53.145,0,0,0-9.895-6.069,137.161,137.161,0,0,0-44.24-18.932C99.1,18.514,107.191-4.662,145.011.824c0,0,7.586,1.242,12.643,4.738,0,0-1.209,1.275-2.8,2.869a1.528,1.528,0,0,0,.165,2.617l7.993,6.278c.088,3.3.22,4.255,2.727,7.256s12.214,13.193,13.49,14.545a3.441,3.441,0,0,1,.429,3.683ZM160.666,20.042l-.22-.1a4.189,4.189,0,0,0-4.628.945l-.264.275L159.7,23.2Z"
              transform="translate(98 266.998)"
            ></path>
            <path
              className="b"
              d="M92.711,44.6c-18.074,1.726-39.435,7.586-59.093,14.93-4.826-16.491,7.7-36.94,49.055-30.2q2.968.478,5.8,1.1A65.161,65.161,0,0,0,92.711,44.6Zm2.32-.209c16.128-1.264,31.355.352,43.976,6.333A137.161,137.161,0,0,0,94.7,31.759a64.293,64.293,0,0,0,.33,12.632Z"
              transform="translate(101.244 269.782)"
            ></path>
            <path
              className="a"
              d="M92.547,11.565a53.233,53.233,0,0,0-.825,21.6q-2.836-.561-5.8-1.1C44.526,25.33,32.047,45.735,36.874,62.27A278.151,278.151,0,0,0,0,79.157c16.249-39,51.551-73.286,81.356-77.793C91.074.088,93.812,6.4,92.547,11.565ZM179.653,42.81s-1.814,3.232-2.628,4.4-3.353,1.957-5.552.9c-1-.473-10.411-5.959-14.006-5.651-18.228,1.594-23.186-5.915-23.769-6.6s-1.682-.363-.9.978c3.991,6.849,15.82,10.027,15.82,10.027a44.669,44.669,0,0,1,3.463,12.676,53.145,53.145,0,0,0-9.895-6.069,137.161,137.161,0,0,0-44.24-18.932C99.1,18.514,107.191-4.662,145.011.824c0,0,7.586,1.242,12.643,4.738,0,0-1.209,1.275-2.8,2.869a1.528,1.528,0,0,0,.165,2.617l7.993,6.278c.088,3.3.22,4.255,2.727,7.256s12.214,13.193,13.49,14.545a3.441,3.441,0,0,1,.429,3.683ZM160.666,20.042l-.22-.1a4.189,4.189,0,0,0-4.628.945l-.264.275L159.7,23.2Z"
              transform="translate(98 266.998)"
            ></path>
          </g>
        </svg>
      </div>
      <div className="ml-4 mb-4 mt-8 ">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="-1418.5 5210.5 407.554 111.6"
          focusable="false"
          role="img"
          alt="Pega logo text"
          preserveAspectRatio="xMaxYMax meet"
          className="w-32 h-11"
        >
          <defs>
            <path
              id="logo-text"
              className="path stroke-white fill-white"
              d="M81.7,40.7C81.7,64.3,63,76.6,40,76.6H22.8V108H.5V4.9H41.9C66.8,4.9,81.7,19.5,81.7,40.7ZM40,25.3H22.8V56.7h18c11.3,0,17.7-6.2,17.7-15.9C58.4,31.2,51.5,25.3,40,25.3Zm80.8,62.2h55.7V108H98.2V4.9h77.7V25.2H120.8V46.6h44.7V66.2H120.8ZM244.5.5c19.3,0,31.2,7.5,37.6,13.4L270.3,30a38.323,38.323,0,0,0-11.1-6.6,44.635,44.635,0,0,0-13.4-1.9c-10.3,0-18.4,3.2-24.8,9.7-6.6,6.3-9.7,14.9-9.7,24.8,0,10.3,3.1,18.6,9.7,25.2A32.282,32.282,0,0,0,243.9,90c9,0,16.7-1.9,22.4-5V65.7H243.2v-19H288V94l-.6.6c-10.3,10.6-24.3,16.5-42.9,16.5-15.9,0-28.7-5.9-39.5-15.8C194.5,85,188.6,72,188.6,55.9c0-15.8,5.9-28.7,16.4-39.5A55.9,55.9,0,0,1,244.5.5ZM363.3,4.9l43.5,103H383l-9.1-23H331.2l-9.4,23H298.7l44.1-103h20.5Zm2.6,60.2L352.3,31.8,338.7,65.1Z"
              transform="translate(-1418.5 5210.5)"
            ></path>
            <clipPath id="clip">
              <use xlinkHref="#logo-text"></use>
            </clipPath>
          </defs>
          <g>
            <use xlinkHref="#logo-text" clipPath="url(#clip)"></use>
          </g>
        </svg>
      </div>
    </div>
  );
};

export default Logo;
