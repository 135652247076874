import React, {useState, useEffect, useRef, memo, useCallback} from "react";
import moment from "moment-timezone";
import {getStatusContent} from "./statusContentHelper"; // Adjust the path as necessary
import {Oval} from "react-loader-spinner"; // Import the spinner
import {Tooltip} from "react-tooltip"; // Import Tooltip using named import

const getBaseUrl = (svc) => {
  switch (svc) {
    case "1":
      return "https://pega.dev.pegaenablement.com/prweb/PRRestService/Provisioning/1.0";
    case "2":
      return "https://pega.prod.pegaenablement.com/prweb/PRRestService/Provisioning/1.0";
    default:
      return "https://exercise.pegaenablement.com/prweb/PRRestService/Provisioning/1.0";
  }
};

const Status = memo(({id, svc}) => {
  const BASE_URL = getBaseUrl(svc);
  // console.log(`BASE_URL: ${BASE_URL}`);
  const [showContent, setShowContent] = useState(false);
  const [statusData, setStatusData] = useState(null);
  const [statusContent, setStatusContent] = useState({
    text: "-----",
    image: "loading-200x200.gif",
    note: "Checking status...",
  });
  const [timerText, setTimerText] = useState("");
  const hasFetchedRef = useRef(false); // Ref to track if fetchStatus has been called
  const intervalIdRef = useRef(null); // Ref to store the interval ID

  const handleError = (error) => {
    console.error(error.message);
    setStatusData({Status: "MAINTENANCE"});
  };

  const fetchStatus = useCallback(async () => {
    if (id && id !== "null") {
      try {
        const response = await fetch(`${BASE_URL}/StudentEvent`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            InstanceCaseID: id,
            Action: "INFO",
          }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        data.Status = data.Status.toUpperCase();
        const statusData = {
          Status: data.Status.toUpperCase(),
          StatusTimestamp: data.StatusTimestamp,
          URL: data.URL,
        };
        console.log("Fetched data:", statusData);
        updateTimer(statusData.Status, statusData.StatusTimestamp);
        setStatusData(statusData);
        // Stop checking if status is stable
        if (
          data.Status === "TERMINATED" ||
          data.Status === "ERROR" ||
          data.Status === "STOPPED" ||
          data.Status === "MAINTENANCE"
        ) {
          clearInterval(intervalIdRef.current);
        }
      } catch (error) {
        handleError(error);
      }
    } else {
      setStatusData({Status: "TERMINATED"});
    }
  }, [id, BASE_URL]);

  useEffect(() => {
    if (!hasFetchedRef.current) {
      // Initial fetch
      fetchStatus();
      hasFetchedRef.current = true; // Mark as fetched
    }
    // Set interval to fetch status every 10 seconds
    intervalIdRef.current = setInterval(fetchStatus, 10000);
    // Cleanup interval on component unmount
    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [fetchStatus]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 600); // Delay for content to appear
    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (statusData) {
      const {text, image, note} = getStatusContent(statusData.Status);
      setStatusContent({text, image, note});
      // Redirect if status is RUNNING
      if (statusData.Status === "RUNNING" && statusData.URL) {
        window.location.href = statusData.URL;
      }
    }
  }, [statusData]);

  useEffect(() => {
    if (statusData && statusData.StatusTimestamp) {
      const intervalId = setInterval(() => {
        updateTimer(statusData.Status, statusData.StatusTimestamp);
      }, 1000);
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
    }
  }, [statusData]);

  const updateTimer = (status, statusTimestamp) => {
    if (!statusTimestamp) {
      return;
    }
    const t0_msec = moment.tz(
      statusTimestamp,
      "YYYYMMDDTHHmmss.SSS [GMT]",
      "GMT"
    );
    const t1_msec = moment.tz();
    const diff = t1_msec.diff(t0_msec);
    const duration_min_sec = moment.utc(diff).format("mm:ss");
    const duration_readable = t0_msec.fromNow();
    if (
      status === "RESUMING" ||
      status === "LAUNCHING" ||
      status === "STOPPING" ||
      status === "PENDING"
    ) {
      setTimerText(`( ${duration_min_sec} )`);
    } else {
      setTimerText(`( ${duration_readable} )`);
    }
  };

  const startInstance = async () => {
    if (id.length < 1) {
      return;
    }
    const service_status_remote_payload_start = JSON.stringify({
      InstanceCaseID: id,
      Action: "start",
    });
    try {
      const response = await fetch(`${BASE_URL}/StudentEvent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: service_status_remote_payload_start,
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("startInstance: RES: " + JSON.stringify(data));
      // Start fetching the status again
      fetchStatus();
      intervalIdRef.current = setInterval(fetchStatus, 10000);
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-0">
      <div className="flex flex-col items-center bg-blue-900 text-white rounded-lg border border-gray-500 p-6 px-20 w-11/12 max-w-md">
        {showContent && (
          <>
            {statusContent.image === "loading-200x200.gif" ? (
              <div className="m-8">
                <Oval
                  height="100"
                  width="100"
                  color="white"
                  secondaryColor="grey"
                  ariaLabel="loading"
                />
              </div>
            ) : statusData && statusData.Status === "STOPPED" ? (
              <div
                onClick={startInstance}
                className="cursor-pointer"
                data-tooltip-id="resume-tooltip"
                data-tooltip-content="Click to resume your instance"
                data-tooltip-place="bottom"
                data-tooltip-offset="-35"
              >
                <img
                  src={`/images/${statusContent.image}`}
                  alt={`Current status: ${statusContent.text}`}
                  className="w-40 h-auto"
                />
                <Tooltip id="resume-tooltip" />
              </div>
            ) : (
              <div>
                <img
                  src={`/images/${statusContent.image}`}
                  alt={`Current status: ${statusContent.text}`}
                  className="w-40 h-auto"
                />
              </div>
            )}
            <p className="text-2xl mb-2">{statusContent.text}</p>
            <p id="status-timer" className="text-sm mb-2">
              {timerText}
            </p>
            <p className="italic text-center">{statusContent.note}</p>
            <p className="hidden">ID: {id}</p>
            <p className="hidden">SVC: {svc}</p>
          </>
        )}
      </div>
    </div>
  );
});

export default Status;
